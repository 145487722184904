const initialState = {
  allData: [],
  data: [],
  total: 1,
  selectedUser: null,
  courseDropdown: [],
  userData: null,
  universityOptionSelect: [],
  userOptionSelect: [],
  evaluatorOptionSelect: [],
  personalStatusOptionSelect: [],
  userOptionSelectEmail: [],
};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ADMIN_USER_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        userData: null,
        universityOptionSelect: action.universityOptionSelect,
        userOptionSelect: action.userOptionSelect,
        evaluatorOptionSelect: action.evaluatorOptionSelect,
        personalStatusOptionSelect: action.personalStatusOptionSelect,
      };
    case "GET_ADMIN_USER_BEAN":
      return {
        ...state,
        userData: action.userData,
      };
    case "GET_ADMIN_USER_DATA_USER_EMAIL":
      return {
        ...state,
        userOptionSelectEmail: action.userOptionSelectEmail,
      };
    default:
      return state;
  }
};

export default courseReducer;
